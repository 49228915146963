import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { CHART_TITLES_IG31_API } from '../../utils/const'
import { useStaticQuery, graphql } from 'gatsby'
import { wrangle31Data } from '../../utils/helpers'
import { GoalId } from '../../styles/OverviewStyles'
import {
  GoalTitle,
  LabelBox,
  NotMeasured
} from '../../styles/CountryPageStyles'
import DataSource from '../DataSource'
import { useTranslation } from 'react-i18next'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid var(--lightGray);
  &:nth-child(n + 12) {
    border-bottom: none;
  }
  &.row-label {
    margin-left: 1rem;
    justify-content: left;
    ${Mq.sm} {
      font-size: 1rem;
    }
    ${Mq.xs} {
      font-size: 0.8rem;
      margin-left: 0;
    }
  }
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Ig31CountryPageChart = ({
  data,
  region,
  country,
  content,
  compareTo,
  dataSrc,
  isClient
}) => {
  const { t } = useTranslation()

  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries(filter: { IA2030_IND: { eq: "IG3.1" } }) {
        nodes {
          COUNTRY
          YEAR
          VALUE_NUM
          DIMENSION2
        }
      }
    }
  `)

  const countryData = rows.nodes.filter((item) => item.COUNTRY === country)
  const compareData = rows.nodes.filter(
    (item) => item.COUNTRY === compareTo?.COUNTRY
  )
  const formattedCountry = wrangle31Data(countryData)
  const getCompareCountry = (data) => {
    if (data.length) {
      return wrangle31Data(data)
    } else return null
  }
  const compareCountry = getCompareCountry(compareData)
  const getValue = (cty, antigen) => {
    if (cty && cty[antigen]) {
      return cty[antigen][cty[antigen].length - 1].value
    } else return null
  }

  const year = data.DTP3[data.DTP3.length - 1].year
  const order = ['DTP3', 'PCV3', 'MCV2', 'HPVc']

  return (
    <>
      <GoalId>3.1</GoalId>
      <GoalTitle className="tall">
        {content.ig31Description}
        <DataSource year={year} dataSrc={dataSrc} indicator={'ig3.1'} />
      </GoalTitle>
      {order.map((key, i) => {
        //country
        const countryValue = getValue(formattedCountry, key)
        const compareValue = getValue(compareCountry, key)

        //region
        const value = data[key]
        const regionValue = value[value.length - 1].value

        return (
          <React.Fragment key={key}>
            <Flex className="row-label">{`${t(
              CHART_TITLES_IG31_API[key]
            )} — ${t(key)}`}</Flex>
            <Flex className="side-border">
              {countryValue === -1 ? (
                <NotMeasured>{t('not measured in this country')}</NotMeasured>
              ) : (
                <LabelBox
                  bgColor={countryValue === -2 ? 'white' : 'lightestGray'}
                  border={countryValue === -2}
                  color={'brightBlue'}
                >
                  {!countryValue ///=== -2
                    ? t('no data')
                    : `${countryValue.toFixed(0)}%`}
                </LabelBox>
              )}
            </Flex>
            <Flex>
              {isClient && compareCountry ? (
                compareValue === -1 ? (
                  <NotMeasured>{t('not measured in this country')}</NotMeasured>
                ) : (
                  <LabelBox
                    bgColor={compareValue === -2 ? 'white' : 'lightestGray'}
                    border={compareValue === -2}
                    color={'darkGray'}
                  >
                    {!compareValue //=== -2
                      ? t('no data')
                      : `${compareValue.toFixed(0)}%`}
                  </LabelBox>
                )
              ) : regionValue === -1 ? (
                <NotMeasured>{t('not measured in this country')}</NotMeasured>
              ) : (
                <LabelBox
                  bgColor={regionValue === -2 ? 'white' : 'lightestGray'}
                  border={regionValue === -2}
                  color={regionValue === -2 ? 'brightBlue' : 'darkGray'}
                >{`${regionValue.toFixed(0)}%`}</LabelBox>
              )}
            </Flex>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Ig31CountryPageChart
