import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'
import { GoalId } from '../../styles/OverviewStyles'
//import { wrangleSp41Data } from '../../utils/helpers'
import { GoalTitle, LabelBox } from '../../styles/CountryPageStyles'
import DataSource from '../DataSource'
import { useTranslation } from 'react-i18next'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Sp41CountryPageChart = ({
  data,
  region,
  country,
  content,
  compareTo,
  dataSrc,
  isClient
}) => {
  const { t } = useTranslation()
  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries(
        filter: { IA2030_IND: { eq: "SP4.1" }, DIMENSION2: { eq: null } }
      ) {
        nodes {
          COUNTRY
          YEAR
          VALUE_NUM
          DIMENSION2
        }
      }
    }
  `)
  const countryData = rows.nodes.filter((item) => item.COUNTRY === country)
  const compareData = rows.nodes.filter(
    (item) => item.COUNTRY === compareTo?.COUNTRY
  )

  // const formattedCountry = countryData //wrangleSp41Data(countryData)

  // const getCompareCountry = (data) => {
  //   if (data) {
  //     return wrangleSp41Data(data)
  //   } else return null
  // }
  // const compareCountry = getCompareCountry(compareData)
  const getValue = (cty) => {
    if (cty.length) {
      return cty[cty.length - 1].VALUE_NUM
    } else return null
  }
  // //country
  const countryValue = getValue(countryData)
  const compareValue = getValue(compareData)

  // //region
  const regionValue = data[data.length - 1].VALUE_NUM
  const matches = useMediaQuery({ query: '(max-width: 900px)' })
  const year = data[data.length - 1].YEAR

  return (
    <>
      <GoalId>4.1</GoalId>
      <GoalTitle className={'wide'}>
        {content.sp41Description}
        <DataSource year={year} dataSrc={dataSrc} indicator={'sp4.1'} />
      </GoalTitle>
      {matches && <Flex></Flex>}
      <Flex className="side-border">
        <LabelBox
          color={'brightBlue'}
          bgColor={countryValue < 0 ? 'white' : 'lightestGray'}
          border={countryValue < 0}
        >
          {countryValue < 0 ? t('no data') : countryValue.toFixed()}
        </LabelBox>
      </Flex>
      <Flex>
        {isClient && compareValue ? (
          <LabelBox
            bgColor={compareValue < 0 ? 'white' : 'lightestGray'}
            color={compareValue < 0 ? 'brightBlue' : 'darkGray'}
            border={compareValue < 0}
          >
            {compareValue < 0 ? t('no data') : compareValue.toFixed()}
          </LabelBox>
        ) : (
          <LabelBox
            bgColor={regionValue < 0 ? 'white' : 'lightestGray'}
            color={regionValue < 0 ? 'brightBlue' : 'darkGray'}
            border={regionValue < 0}
          >
            {regionValue < 0 ? t('no data') : regionValue.toFixed()}
          </LabelBox>
        )}
      </Flex>
    </>
  )
}

export default Sp41CountryPageChart
