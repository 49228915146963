import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'
import { GoalId } from '../../styles/OverviewStyles'
import {
  GoalTitle,
  NotMeasured,
  LabelBox
} from '../../styles/CountryPageStyles'
import { useTranslation } from 'react-i18next'
import DataSource from '../DataSource'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Sp63CountryPageChart = ({
  dataSrc,
  content,
  country,
  compareTo,
  isClient
}) => {
  const { t } = useTranslation()
  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries(filter: { IA2030_IND: { eq: "SP6.3" } }) {
        nodes {
          COUNTRY
          YEAR
          YEAR_RANGE
          VALUE_STR
        }
      }
    }
  `)
  //same as wrangle in overview chart? factor out?
  // const wrangleSP63Data = (data) => {
  //   const allYears = []
  //   const allData = Object.entries(data)
  //     .map(([key, value]) => {
  //       const keysToExclude = ['memberState', 'iSO3', 'id', 'region']

  //       if (!keysToExclude.includes(key) && value) {
  //         const years = key.slice(-8)
  //         const year1 = years.slice(0, 4)
  //         const year2 = years.slice(-4)
  //         const yearWithHyphen = `${year1}-${year2}`
  //         let category
  //         //gsheets plugin makes key start with lower case, so this identifies the beginning of a key
  //         if (key.includes('decrease')) {
  //           category = 1
  //         } else if (key.includes('increase')) {
  //           category = 3
  //         } else category = 2
  //         allYears.push(yearWithHyphen)
  //         return { year: yearWithHyphen, category, value }
  //       } else return null
  //     })

  //     .filter((item) => item)

  //   const years = Array.from(new Set(allYears)).sort()
  //   return years.map((year) => {
  //     const total = allData
  //       .filter((d) => d.year === year)
  //       .reduce((acc, d) => {
  //         return acc + d.value
  //       }, 0)

  //     const categoriesToInclude = [3]
  //     const subtotal = allData
  //       .filter(
  //         (d) => d.year === year && categoriesToInclude.includes(d.category)
  //       )
  //       .reduce((acc, d) => {
  //         return acc + d.value
  //       }, 0)
  //     return { year, total, subtotal }
  //   })
  // }
  const countryData = rows.nodes.filter((item) => item.COUNTRY === country)
  const compareData = rows.nodes.filter(
    (item) => item.COUNTRY === compareTo?.COUNTRY
  )
  // const formattedCountry = wrangleSP63Data(countryData)
  // const getCompareCountry = (data) => {
  //   if (data) {
  //     return wrangleSP63Data(data)
  //   } else return null
  // }
  // const compareCountry = getCompareCountry(compareData)
  const getValue = (cty) => {
    //This now handles blank cell which mean not applicable in this country --> as of 9/23. no more blank cells.
    if (cty && cty.length) {
      return cty[cty.length - 1].VALUE_STR.toLowerCase()
      // if (cty.length) {
      //   return cty[cty.length - 1].subtotal > 0
      //     ? 'yes'
      //     : cty[cty.length - 1].subtotal === 0
      //     ? 'no'
      //     : cty[cty.length - 1].subtotal === -2
      //     ? 'no data'
      //     : cty[cty.length - 1].subtotal === -1
      //     ? -1
      //     : null
      // } else return -1
    } else return 'no data'
  }
  const countryValue = getValue(countryData)
  //console.log('format country', formattedCountry)
  const compareValue = getValue(compareData)
  //console.log('country val', countryValue)
  const yearFromData = countryData.length
    ? countryData[countryData.length - 1].YEAR_RANGE
    : null
  //some countries are blank because this indicator doesn't apply. In that case, need to hard code year
  const year = yearFromData ?? ''
  const matches = useMediaQuery({ query: '(max-width: 900px)' })
  return (
    <>
      <GoalId>6.3</GoalId>
      <GoalTitle className={'wide'}>
        {content.sp63Description}
        <DataSource year={year} dataSrc={dataSrc} />
      </GoalTitle>
      {matches && <Flex></Flex>}
      <Flex className="side-border">
        {countryValue === -1 ? (
          <NotMeasured>{t('not applicable')}</NotMeasured>
        ) : (
          <LabelBox
            color={
              countryValue === 'yes' || countryValue === 'no'
                ? 'white'
                : 'brightBlue'
            }
            bgColor={
              countryValue === 'yes'
                ? 'brightBlue'
                : countryValue === 'no'
                ? 'darkOrange'
                : 'white'
            }
            border={countryValue === 'no data'}
          >
            {t(countryValue)}
          </LabelBox>
        )}
      </Flex>
      <Flex>
        {isClient && compareTo ? (
          <LabelBox
            color={
              compareValue === 'yes' || compareValue === 'no'
                ? 'white'
                : 'brightBlue'
            }
            bgColor={
              compareValue === 'yes'
                ? 'brightBlue'
                : compareValue === 'no'
                ? 'darkOrange'
                : 'white'
            }
            border={compareValue === 'no data'}
          >
            {t(compareValue)}
          </LabelBox>
        ) : (
          <NotMeasured>{t('not shown at the regional level')}</NotMeasured>
        )}
      </Flex>
    </>
  )
}

export default Sp63CountryPageChart
