import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useStaticQuery, graphql } from 'gatsby'
import { wrangleSp32Data } from '../../utils/helpers'
import { GoalId } from '../../styles/OverviewStyles'
import {
  GoalTitle,
  LabelBox,
  NotMeasured
} from '../../styles/CountryPageStyles'
import DataSource from '../DataSource'
import { useTranslation } from 'react-i18next'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid var(--lightGray);
  &:nth-child(n + 9) {
    border-bottom: none;
  }
  &.row-label {
    margin-left: 1rem;
    justify-content: left;
    ${Mq.sm} {
      font-size: 1rem;
    }
    ${Mq.xs} {
      font-size: 0.8rem;
      margin-left: 0;
    }
  }
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`
//This is SP3.2 -- was having capitalization problems with github
const Sp32CountryPageChart = ({
  regionData,
  region,
  country,
  content,
  compareTo,
  dataSrc,
  isClient
  //rows
}) => {
  const { t } = useTranslation()

  //leaving out prior years because don't need them
  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries(filter: { IA2030_IND: { eq: "SP3.2" } }) {
        nodes {
          COUNTRY
          YEAR
          DIMENSION2
          VALUE_NUM
        }
      }
    }
  `)
  const countryData = rows.nodes.filter((item) => item.COUNTRY === country)
  const compareData = rows.nodes.filter(
    (item) => item.COUNTRY === compareTo?.COUNTRY
  )

  //need to back fill country data so that arrays are the same length as the region arrays
  const fillInCountryData = (country) => {
    //get years and anitgens represented in region data
    const yearsInRegion = regionData
      .map((d) => d.map((item) => item.YEAR))
      .flat()
    const regionYears = Array.from(new Set(yearsInRegion))
    const antigensInRegion = regionData
      .map((d) => d.map((item) => item.DIMENSION2))
      .flat()
    const regionAntigens = Array.from(new Set(antigensInRegion))
    //compare region to country
    const countryAntigens = country.map((item) => item[0].DIMENSION2)
    regionAntigens.forEach((a) => {
      const array = []
      if (!countryAntigens.includes(a)) {
        regionYears.forEach((y) => {
          array.push({ YEAR: y, VALUE_NUM: -2 }) //using -2 as that was the original value from the gSheets to indicate no data
        })
        country.push(array)
      }
    })
    return country.map((array) => {
      const countryYears = array.map((item) => item.YEAR)
      regionYears.forEach((y) => {
        if (!countryYears.includes(y)) array.push({ YEAR: y, VALUE_NUM: -2 })
      })
      return array
    })
  }
  const formattedCountry = fillInCountryData(wrangleSp32Data(countryData))

  const getCompareCountry = (data) => {
    if (data && data.length) {
      return fillInCountryData(wrangleSp32Data(data))
    } else return null
  }
  const compareCountry = getCompareCountry(compareData)
  const getValue = (cty, i) => {
    if (cty && cty.length) {
      return cty[i][cty[i].length - 1].VALUE_NUM
    } else return null
  }

  const year = regionData[0][regionData[0].length - 1].YEAR

  return (
    <>
      <GoalId>3.2</GoalId>
      <GoalTitle className="tall">
        {content.sp32Description}
        <DataSource year={year} dataSrc={dataSrc} indicator={'sp3.2'} />
      </GoalTitle>
      {regionData.map((array, i) => {
        //country
        const countryValue = getValue(formattedCountry, i)
        const compareValue = getValue(compareCountry, i)

        //region
        const regionValue = array[array.length - 1].VALUE_NUM
        return (
          <React.Fragment key={array[0].DIMENSION2}>
            <Flex className="row-label">{`${t(array[0].DIMENSION2)}`}</Flex>
            <Flex className="side-border">
              {countryValue === -1 ? (
                <NotMeasured>{t('not measured in this country')}</NotMeasured>
              ) : (
                <LabelBox
                  bgColor={countryValue === -2 ? 'white' : 'lightestGray'}
                  border={countryValue === -2}
                  color={'brightBlue'}
                >
                  {countryValue === -2
                    ? t('no data')
                    : `${countryValue.toFixed()}%`}
                </LabelBox>
              )}
            </Flex>
            <Flex>
              {isClient && compareCountry ? (
                compareValue === -1 ? (
                  <NotMeasured>{t('not measured in this country')}</NotMeasured>
                ) : (
                  <LabelBox
                    bgColor={compareValue === -2 ? 'white' : 'lightestGray'}
                    border={compareValue === -2}
                    color={compareValue === -2 ? 'brightBlue' : 'darkGray'}
                  >
                    {compareValue === -2
                      ? t('no data')
                      : `${compareValue.toFixed()}%`}
                  </LabelBox>
                )
              ) : regionValue === -1 ? (
                <NotMeasured>{t('not measured in this region')}</NotMeasured>
              ) : (
                <LabelBox
                  bgColor={regionValue === -2 ? 'white' : 'lightestGray'}
                  border={regionValue === -2}
                  color={regionValue === -2 ? 'brightBlue' : 'darkGray'}
                >{`${regionValue.toFixed()}%`}</LabelBox>
              )}
            </Flex>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Sp32CountryPageChart
