import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'
import { GoalId } from '../../styles/OverviewStyles'
//import { wrangleSP62Data } from '../../utils/helpers'
import {
  GoalTitle,
  LabelBox,
  NotMeasured
} from '../../styles/CountryPageStyles'
import DataSource from '../DataSource'
import { useTranslation } from 'react-i18next'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Sp62CountryPageChart = ({
  //data,
  //region,
  country,
  content,
  compareTo,
  dataSrc,
  isClient
}) => {
  const { t } = useTranslation()
  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries(filter: { IA2030_IND: { eq: "SP6.2" } }) {
        nodes {
          COUNTRY
          YEAR
          YEAR_RANGE
          VALUE_STR
        }
      }
    }
  `)
  const countryData = rows.nodes.filter((item) => item.COUNTRY === country)
  const compareData = rows.nodes.filter(
    (item) => item.COUNTRY === compareTo?.COUNTRY
  )
  // const formattedCountry = wrangleSP62Data(countryData)
  // // console.log('6.2 format country', formattedCountry)

  // const getCompareCountry = (data) => {
  //   if (data) {
  //     return wrangleSP62Data(data)
  //   } else return null
  // }
  //const compareCountry = getCompareCountry(compareData)
  const getValue = (cty) => {
    //-2 means missing in this country --> 9/23 now have blanks
    if (cty && cty.length) {
      return cty[cty.length - 1].VALUE_STR.toLowerCase()
    } else return 'no data'
  }
  // //country
  const countryValue = getValue(countryData)
  const compareValue = getValue(compareData)
  // //region
  // const regionValue =
  //   data[data.length - 1].subtotal < 0
  //     ? -2
  //     : data[data.length - 1].subtotal / data[data.length - 1].total
  const matches = useMediaQuery({ query: '(max-width: 900px)' })

  //handles year when year has no data
  const yearFromData = countryData.length
    ? countryData[countryData.length - 1].YEAR_RANGE
    : null
  const year = yearFromData ?? ''
  return (
    <>
      <GoalId>6.2</GoalId>
      <GoalTitle className={'wide'}>
        {content.sp62Description}
        <DataSource year={year} dataSrc={dataSrc} indicator={'sp6.2'} />
      </GoalTitle>
      {matches && <Flex></Flex>}
      <Flex className="side-border">
        <LabelBox
          color={
            countryValue === 'yes' || countryValue === 'no'
              ? 'white'
              : 'brightBlue'
          }
          bgColor={
            countryValue === 'yes'
              ? 'brightBlue'
              : countryValue === 'no'
              ? 'darkOrange'
              : 'white'
          }
          border={countryValue === 'no data'}
        >
          {t(countryValue)}
        </LabelBox>
      </Flex>
      <Flex>
        {isClient && compareTo ? (
          <LabelBox
            color={
              compareValue === 'yes' || compareValue === 'no'
                ? 'white'
                : 'brightBlue'
            }
            bgColor={
              compareValue === 'yes'
                ? 'brightBlue'
                : compareValue === 'no'
                ? 'darkOrange'
                : 'white'
            }
            border={compareValue === 'no data'}
          >
            {t(compareValue)}
          </LabelBox>
        ) : (
          <NotMeasured>{t('not shown at the regional level')}</NotMeasured>
          // <LabelBox
          //   bgColor={regionValue < 0 ? 'white' : 'lightestGray'}
          //   color={regionValue < 0 ? 'brightBlue' : 'darkGray'}
          //   border={regionValue < 0}
          // >
          //   {regionValue < 0
          //     ? t('no data')
          //     : `${regionValue.toFixed(2) * 100}%`}
          // </LabelBox>
        )}
      </Flex>
    </>
  )
}

export default Sp62CountryPageChart
