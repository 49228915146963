import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'
import { GoalId } from '../../styles/OverviewStyles'
import { GoalTitle, LabelBox } from '../../styles/CountryPageStyles'
//import { wrangleSPResponseData } from '../../utils/helpers'
import DataSource from '../DataSource'
import { useTranslation } from 'react-i18next'
//import { NUM_COUNTRIES_IN_REG } from '../../utils/const'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Sp71CountryPageChart = ({
  data,
  region,
  country,
  content,
  compareTo,
  dataSrc,
  isClient
}) => {
  const { t } = useTranslation()
  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries(filter: { IA2030_IND: { eq: "SP7.1" } }) {
        nodes {
          COUNTRY
          YEAR
          VALUE_STR
        }
      }
    }
  `)

  const countryData = rows.nodes.filter((item) => item.COUNTRY === country)
  const compareData = rows.nodes.filter(
    (item) => item.COUNTRY === compareTo?.COUNTRY
  )

  // const formattedCountry = wrangleSPResponseData(countryData)
  // const getCompareCountry = (data) => {
  //   if (data) {
  //     return wrangleSPResponseData(data)
  //   } else return null
  // }
  // const compareCountry = getCompareCountry(compareData)

  const getValue = (cty) => {
    if (cty && cty.length) {
      const value = cty[cty.length - 1].VALUE_STR
      return value === 'ND_NR_NORESPONSE' ? 'no data' : value.toLowerCase()
    } else return null
  }
  //country
  const countryValue = getValue(countryData)
  const compareValue = getValue(compareData)
  //region
  //const regionValue = data[data.length - 1].yes
  //const regionValueDenominator = NUM_COUNTRIES_IN_REG[region]
  //data[data.length - 1].yes + data[data.length - 1].no
  const matches = useMediaQuery({ query: '(max-width: 900px)' })
  const year = data.year //ata[data.length - 1].year
  return (
    <>
      <GoalId>7.1</GoalId>
      <GoalTitle className={'wide'}>
        {content.sp71Description}
        <DataSource year={year} dataSrc={dataSrc} indicator={'sp7.1'} />
      </GoalTitle>
      {matches && <Flex></Flex>}
      <Flex className="side-border">
        <LabelBox
          color={
            countryValue === 'yes' || countryValue === 'no'
              ? 'white'
              : 'brightBlue'
          }
          bgColor={
            countryValue === 'yes'
              ? 'brightBlue'
              : countryValue === 'no'
              ? 'darkOrange'
              : 'white'
          }
          border={countryValue === 'no data'}
        >
          {t(countryValue)}
        </LabelBox>
      </Flex>
      <Flex>
        {isClient && compareValue ? (
          <LabelBox
            color={
              compareValue === 'yes' || compareValue === 'no'
                ? 'white'
                : 'brightBlue'
            }
            bgColor={
              compareValue === 'yes'
                ? 'brightBlue'
                : compareValue === 'no'
                ? 'darkOrange'
                : 'white'
            }
            border={compareValue === 'no data'}
          >
            {t(compareValue)}
          </LabelBox>
        ) : (
          <LabelBox
            bgColor={'lightestGray'}
            //size={regionValue > 1000 ? 'sm' : ''}
          >
            {data.value}
          </LabelBox>
        )}
      </Flex>
    </>
  )
}

export default Sp71CountryPageChart
