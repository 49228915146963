import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'
import { GoalId } from '../../styles/OverviewStyles'
import { GoalTitle, LabelBox } from '../../styles/CountryPageStyles'
//import { wrangleSP12Data } from '../../utils/helpers'
import DataSource from '../DataSource'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Sp12CountryPageChart = ({
  data,

  region,
  country,
  content,
  compareTo,
  dataSrc,
  isClient
}) => {
  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries(filter: { IA2030_IND: { eq: "SP1.2" } }) {
        nodes {
          COUNTRY
          YEAR
          VALUE_NUM
        }
      }
    }
  `)

  const countryData = rows.nodes.filter((item) => item.COUNTRY === country)
  const compareData = rows.nodes.filter(
    (item) => item.COUNTRY === compareTo?.COUNTRY
  )

  //const formattedCountry = wrangleSP12Data(countryData)
  // const getCompareCountry = (data) => {
  //   if (data) {
  //     return wrangleSP12Data(data)
  //   } else return null
  // }
  // const compareCountry = getCompareCountry(compareData)

  const getValue = (cty) => {
    if (cty && cty.length) {
      //anticipating that we might need to enter -2 for missing data b/c wrangle function leaves out null fields
      return cty[cty.length - 1].VALUE_NUM
    } else return null
  }
  //country
  const countryValue = getValue(countryData)
  const compareValue = getValue(compareData)
  //region
  const regionValue = data[data.length - 1].VALUE_NUM
  const matches = useMediaQuery({ query: '(max-width: 900px)' })
  const year = data[data.length - 1].YEAR
  return (
    <>
      <GoalId>1.2</GoalId>
      <GoalTitle className={'wide'}>
        {content.sp12Description}
        <DataSource year={year} dataSrc={dataSrc} indicator={'sp1.2'} />
      </GoalTitle>
      {matches && <Flex></Flex>}
      <Flex className="side-border">
        <LabelBox
          color={'brightBlue'}
          bgColor={countryValue < 0 ? 'white' : 'lightestGray'}
          border={countryValue < 0}
        >
          {countryValue < 0 ? 'No Data' : countryValue}
        </LabelBox>
      </Flex>
      <Flex>
        {isClient && compareData.length ? (
          <LabelBox
            bgColor={compareValue < 0 ? 'white' : 'lightestGray'}
            color={compareValue < 0 ? 'brightBlue' : 'darkGray'}
            border={compareValue < 0}
          >
            {compareValue < 0 ? 'No Data' : compareValue}
          </LabelBox>
        ) : (
          <LabelBox
            bgColor={regionValue < 0 ? 'white' : 'lightestGray'}
            color={regionValue < 0 ? 'brightBlue' : 'darkGray'}
            border={regionValue < 0}
          >
            {regionValue < 0 ? 'No Data' : regionValue}
          </LabelBox>
        )}
      </Flex>
    </>
  )
}

export default Sp12CountryPageChart
