import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'
import { GoalId } from '../../styles/OverviewStyles'
//import { wrangle22Data } from '../../utils/helpers'
import {
  GoalTitle,
  LabelBox,
  NotMeasured
} from '../../styles/CountryPageStyles'
import DataSource from '../DataSource'
import { useTranslation } from 'react-i18next'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Ig22CountryPageChart = ({
  data,
  region,
  country,
  content,
  compareTo,
  dataSrc,
  isClient
}) => {
  const { t } = useTranslation()
  //const formatThous = format(',.0f')
  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries(
        filter: { IA2030_IND: { eq: "IG2.2" }, DIMENSION2: { eq: null } }
      ) {
        nodes {
          COUNTRY
          YEAR
          VALUE_NUM
          DIMENSION2
        }
      }
    }
  `)
  const years = data.map((d) => d.YEAR)
  const countryData = rows.nodes.filter((item) => item.COUNTRY === country)

  //if all years not included for each country, add them with a zero value.
  const formattedCountry = years.map((y) => {
    const x = countryData.find((d) => d.YEAR === y)
    if (x) return x
    else return { VALUE_NUM: 'no data', YEAR: y }
  })

  const compareData = rows.nodes.filter(
    (item) => item.COUNTRY === compareTo?.COUNTRY
  )
  const formatCompare = (data) => {
    if (data.length) {
      return years.map((y) => {
        const x = data.find((d) => d.YEAR === y)
        if (x) return x
        else return { VALUE_NUM: 'no data', YEAR: y }
      })
    } else return null
  }
  const formattedCompared = formatCompare(compareData)
  //console.log('cnty', countryData)
  //console.log('cmp', compareData)

  //const formattedCountry = wrangle22Data(countryData)
  // const getCompareCountry = (data) => {
  //   if (data) {
  //     return wrangle22Data(data)
  //   } else return null
  // }
  // const compareCountry = getCompareCountry(compareData)
  const getValue = (cty) => {
    if (cty && cty.length) {
      return cty[cty.length - 1].VALUE_NUM
    } else return null
  }
  //country
  const countryValue = getValue(formattedCountry)
  const compareValue = getValue(formattedCompared)

  //console.log('fcnty', formattedCountry)
  //console.log('fcmp', formattedCompared)
  //region

  const regionValue = data[data.length - 1].VALUE_NUM
  const matches = useMediaQuery({ query: '(max-width: 900px)' })
  const year = data[data.length - 1].YEAR
  return (
    <>
      <GoalId>2.2</GoalId>
      <GoalTitle className={'wide'}>
        {content.ig22Description}
        <DataSource year={year} dataSrc={dataSrc} indicator={'ig2.2'} />
      </GoalTitle>
      {matches && <Flex></Flex>}
      <Flex className="side-border">
        {countryValue === -1 ? (
          <NotMeasured>{t('not measured in this country')}</NotMeasured>
        ) : (
          <LabelBox
            color={'brightBlue'}
            bgColor={countryValue === -2 ? 'white' : 'lightestGray'}
            border={countryValue === -2}
          >
            {t(countryValue)}
          </LabelBox>
        )}
      </Flex>
      <Flex>
        {isClient && formattedCompared && compareValue === -1 ? (
          <NotMeasured>{t('not measured in this country')}</NotMeasured>
        ) : isClient && formattedCompared ? (
          <LabelBox
            bgColor={compareValue === -2 ? 'white' : 'lightestGray'}
            border={countryValue === -2}
            color={'darkGray'}
          >
            {t(compareValue)}
          </LabelBox>
        ) : (
          <LabelBox
            bgColor={regionValue < 0 ? 'white' : 'lightestGray'}
            color={regionValue < 0 ? 'brightBlue' : 'darkGray'}
            border={regionValue < 0}
          >
            {regionValue < 0 ? t('no data') : regionValue}
          </LabelBox>
        )}
      </Flex>
    </>
  )
}

export default Ig22CountryPageChart
